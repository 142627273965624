#sidebar{
    width: 250px;
    height: 100vh;
    overflow-y: auto;
    transition: all 0.3s;
}

#content {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    transition: all 0.3s;
}

@media (max-width: 768px) {
    #sidebar{
        position: fixed;
        top: 0;
        left: -250px;
        z-index: 999;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    }
    #sidebar.active {
        left: 0;
    }
    #dismiss {
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        background: #7386D5;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }
    #dismiss:hover {
        background: #fff;
        color: #7386D5;
    }
    .overlay {
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.7);
        z-index: 998;
        opacity: 0;
        transition: all 0.5s ease-in-out;
    }
    .overlay.active {
        display: block;
        opacity: 1;
    }
    #content{
        position: absolute;
        top: 0;
        right: 0;
    }
}

@media (min-width: 769px) {
    .wrapper{
        display: flex;
        width: 100%;
        align-items: stretch;
    }
    #dismiss{
        display: none;
    }
    .overlay{
        display: none;
    }
    #content{
        flex: 1;
    }
}