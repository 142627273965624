.sidebar{
    position: relative;
    z-index: 100000;
    display: flex; flex-flow: column;
    width: 250px; height: 100vh; overflow-y: auto; overflow-x: visible; background: #23272b; font-size: 16px;
    font-family: Roboto, Arial, sans-serif; font-weight: 400;
}

.sidebar .handle{
    position: absolute; top: 0; right: 0; margin-right: -20px; z-index: 1001;
}

.sidebar .body {
    flex: 1;
}

.sidebar ul{
    list-style: none; display: block; margin: 0; padding: 0; transition: all 0.5s ease;
}

.sidebar ul > li {
    display: inline-block; width: 100%; margin-left: 0; padding-left: 0;
}

.sidebar ul > li > div{
    display: flex; flex-flow: row;
    color: rgba(255, 255, 255, 0.6);
}
.sidebar a {
    display: block;
    padding: 0 15px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
    line-height: 2.3;
    vertical-align: middle;
    text-decoration: none;
}
.sidebar a:hover{
    color: rgba(255, 255, 255, 0.8); background-color: #181c1d;
}
.sidebar a:focus{
    color: rgba(255, 255, 255, 0.8); background-color: #181c1d;
}
.sidebar a:active{
    color: rgba(255, 255, 255, 1); background-color: #000000;
}
.sidebar a.selected{
    color: rgba(255, 255, 255, 1); background-color: #0077be;
}
.sidebar ul > li > div > a:first-child{
    flex: 1;
}
.sidebar ul > li > ul {
    margin-left: 20px;
    transition: all 0.5s;
}
/*.sidebar ul > li > ul. {

}*/

.sidebar .header {
    border-bottom: 1px solid rgba(255, 255, 255, .4);
}
.sidebar .header a{
    text-align: center;
    font-size: 1.4rem;
    line-height: 2.5;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.sidebar .footer{
    border-top: 1px solid rgba(255, 255, 255, .4);
    text-align: center;
}
.sidebar .footer a{
    font-size: .7rem;
}
